import React, { useEffect, useState, useMemo } from 'react';
import './Clock.css'; // Import the CSS file

const Clock = () => {
  const [time, setTime] = useState(new Date());
  const [showBackupHand, setShowBackupHand] = useState(false); // Toggle for backup second hand
  const [disableTransition, setDisableTransition] = useState(false); // Temporarily disable transitions
  const [buSecondsAngle, setBuSecondsAngle] = useState();
  const [secondsAngle, setSecondsAngle] = useState();
  
  useEffect(() => {
    let currentDate = new Date();
    let lastSyncTime = currentDate.getTime();

    const shouldResynchronize = (seconds) => seconds > 50; // Resync near the end of a minute

    const timer = setInterval(() => {
      // if (elapsedMs >= 60000 && shouldResynchronize(currentDate.getSeconds())) {
      if (currentDate.getSeconds() === 10) {
        console.log('Resyncing time:', currentDate.getSeconds());
        currentDate = new Date(); // Reset to system time
        // console.log(currentDate.getTime() - lastSyncTime)

        lastSyncTime = currentDate.getTime();
      } else {
        currentDate.setSeconds(currentDate.getSeconds() + 1); // Increment by 1 second
      }

      // console.log(currentDate.getTime())
      setTime(new Date(currentDate)); // Update state
    }, 1000);

    // Handle tab regain focus for immediate resync
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setDisableTransition(true); // Temporarily disable transitions
        currentDate = new Date();
        lastSyncTime = currentDate.getTime();
        setTime(new Date(currentDate)); // Sync immediately

        // Re-enable transitions after .1 second
        setTimeout(() => {
          setDisableTransition(false);
        }, 100);
        console.log('Tab regained focus, resyncing...', lastSyncTime);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(timer);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);



  const handAngles = useMemo(() => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    const hourAngle = (hours % 12) * 30 + minutes * 0.5; // 30° per hour, +0.5° per minute
    const minuteAngle = minutes * 6 + seconds * 0.1; // 6° per minute, +0.1° per second
    const secondAngle = seconds * 6; // 6° per second

    // const buSecondAngle = seconds === 0 ? 360 : 354;
    // const buSecondAngle = secondAngle === 0 ? 366 : 354; // we are waiting 2 seconds

    // if (secondAngle === 55 * 6) {
    //   setBuSecondsAngle(secondAngle)
    // } else {
    //   setBuSecondsAngle(prev => prev + 6)
    // }

    setBuSecondsAngle(prev => secondAngle === 55 * 6 ? secondAngle : prev + 6)
    setShowBackupHand(secondAngle === 0)

    // if (secondAngle === 0) {
    //   setShowBackupHand(true); // Show backup hand at the top of the minute
    // setTimeout(() => setShowBackupHand(false), 1000); // Hide backup hand after transition
    // }

    return { hourAngle, minuteAngle, secondAngle };
  }, [time]);


  const { hourAngle, minuteAngle, secondAngle } = handAngles;

  // useEffect(() => {
  //   if (secondAngle === 0) {
  //     setShowBackupHand(true); // Show backup hand at the top of the minute
  //     setTimeout(() => setShowBackupHand(false), 1000); // Hide backup hand after transition
  //   }
  // }, [secondAngle]);

  function getDateInfo(date) {
    const monthNames = [
      'jan', 'feb', 'mar', 'apr', 'maí', 'jún',
      'júl', 'ágú', 'sep', 'okt', 'nóv', 'des'
    ];
    const dayNames = [
      'sunnudagur', 'mánudagur', 'þriðjudagur', 'miðvikudagur', 'fimmtudagur', 'föstudagur', 'laugardagur'
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const wday = dayNames[date.getDay()];

    return { dayMonth: `${day}. ${month}`, weekDay: wday };
  }

  const dateInfo = getDateInfo(time);

  return (
    <div className="clock-container">
      {/* Dial */}
      <img className="clock-dial" src="dial.png" alt="Clock Dial" />

      {/* Hour Hand */}
      <img
        className="clock-hand hour-hand"
        src="smallhand.png"
        alt="Hour Hand"
        style={{
          transform: `rotate(${hourAngle + 180}deg)`,
        }}
      />

      {/* Minute Hand */}
      <img
        className="clock-hand minute-hand"
        src="bighand.png"
        alt="Minute Hand"
        style={{
          transform: `rotate(${minuteAngle + 180}deg)`,
        }}
      />

      {/* Current Second Hand */}
      <img
        className="clock-hand second-hand"
        src="secondhand.png"
        alt="Second Hand"
        style={{
          transform: `rotate(${secondAngle + 189}deg)`,
          transition: disableTransition ? 'none' : 'transform 1s linear', // Temporarily disable transition
          opacity: showBackupHand ? 0 : 1,
        }}
      />

      {/* Backup Second Hand */}
      <img
        className="clock-hand backup-hand"
        src="secondhand.png"
        alt="Backup Second Hand"
        style={{
          transform: `rotate(${buSecondsAngle + 189}deg)`,
          transition: 'transform 1s linear', // Smooth movement
          opacity: showBackupHand && buSecondsAngle ? 1 : 0, // Toggle visibility using opacity
        }}
      />
      <div className="date-day">{dateInfo.dayMonth}</div>
      <div className="date-weekday">{dateInfo.weekDay}</div>
    </div>
  );
};

export default Clock;
